import { type ShowToastConfig, useDMToast } from "@supernovaio/dm"

import { getClientSdk } from "@supernovaio/cloud/utils/data/getClientSdk"
import { Supernova } from "@supernovaio/sdk"

import { useQueryClient } from "@tanstack/react-query"

import { useSafeMutation } from "./useSafeMutation"

export function useSDKMutation<T = void>(params: {
  getToastParams?: (
    data: unknown,
    variables: T
  ) => ShowToastConfig | undefined | null
  mutationFn: (sdk: Supernova, args: T) => Promise<any>
  onSuccessfulMutation?: () => void
  queryKeyToInvalidate?: Array<string | Record<string, string>>
}) {
  const toast = useDMToast()
  const queryClient = useQueryClient()

  return useSafeMutation<unknown, Error, T>({
    mutationFn: async (args: T) => {
      const sdk = await getClientSdk()

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const result = await params.mutationFn(sdk, args)

      if (params.queryKeyToInvalidate) {
        await queryClient.invalidateQueries({
          queryKey: params.queryKeyToInvalidate,
        })
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return result
    },
    onError: () => {
      if (params.queryKeyToInvalidate) {
        queryClient
          .invalidateQueries({
            queryKey: params.queryKeyToInvalidate,
          })
          .then(
            () => {},
            () => {}
          )
      }
    },
    onSuccess: (data, variables) => {
      const toastParams = params.getToastParams?.(data, variables)

      if (toastParams) {
        toast.show(toastParams)
      }

      return params.onSuccessfulMutation?.()
    },
  })
}
