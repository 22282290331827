"use client"

import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from "react"

import { DMThemeGeneratedProvider, useSystemColorScheme } from "@supernovaio/dm"
import {
  themePresetsDefinition,
  SelectedTheme,
} from "@supernovaio/dm/themes/darkmatter/themePresetsDefinition"

import { UserTheme } from "@supernovaio/sdk"

import { useMe } from "../../hooks/data/useMe"
import { useSaveTheme } from "../../hooks/data/useSaveTheme"
import { useUIContext } from "../../utils/UIContextProvider"
import { useFeatureFlags } from "../../utils/featureFlags/useFeatureFlags"

const defaultLightTheme = themePresetsDefinition.Default
const defaultDarkTheme = themePresetsDefinition.SpaceBlue

const UserThemeContext = createContext<{
  selectedTheme: SelectedTheme
  applyTheme: (theme: UserTheme, syncWithBackend?: boolean) => void
}>({
  selectedTheme: defaultLightTheme,
  applyTheme: () => {},
})

export function useUserTheme() {
  return useContext(UserThemeContext)
}

export function UserThemeProvider({ children }: { children: React.ReactNode }) {
  const { enableAppTheming } = useFeatureFlags()
  const { data: me } = useMe()
  const { systemColorScheme } = useSystemColorScheme()
  const { selectedTheme, setSelectedTheme } = useUIContext()
  const { saveTheme } = useSaveTheme()

  const applyTheme = useCallback(
    (newTheme: UserTheme, syncWithBackend = true) => {
      setSelectedTheme({ ...newTheme })
      if (syncWithBackend) {
        saveTheme(newTheme)
      }
    },
    [setSelectedTheme, saveTheme]
  )

  const theme = useMemo(() => {
    const themeToApply =
      systemColorScheme === "light" ? defaultLightTheme : defaultDarkTheme

    if (!selectedTheme) {
      return defaultLightTheme
    }

    if (selectedTheme.preset === "SystemPreference") {
      return {
        ...themeToApply,
        ...themePresetsDefinition.SystemPreference,
        isEditorWhite: selectedTheme.isEditorWhite,
      }
    }

    return selectedTheme.preset === "Custom"
      ? {
          ...selectedTheme,
          ...themePresetsDefinition.Custom,
          backgroundColor:
            selectedTheme.backgroundColor ?? themeToApply.backgroundColor,
          accentColor: selectedTheme.accentColor ?? themeToApply.accentColor,
          contrast: selectedTheme.contrast ?? themeToApply.contrast,
          isSecondaryEnabled:
            selectedTheme.isSecondaryEnabled ?? themeToApply.isSecondaryEnabled,
          secondaryBackgroundColor:
            selectedTheme.secondaryBackgroundColor ??
            themeToApply.secondaryBackgroundColor,
          secondaryContrast:
            selectedTheme.secondaryContrast ?? themeToApply.secondaryContrast,
        }
      : {
          ...themePresetsDefinition[selectedTheme.preset],
          isEditorWhite: selectedTheme.isEditorWhite,
        }
  }, [selectedTheme, systemColorScheme])

  useEffect(() => {
    if (me?.profile.theme) {
      setSelectedTheme(me.profile.theme)
    }
    // TODO: any function in ui context changes reference when ui context changes. It cannot be used as a dependency. The ui context has to be reworked :pray:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me?.profile.theme])

  const contextValue = useMemo(
    () => ({
      selectedTheme: theme,
      applyTheme,
    }),
    [applyTheme, theme]
  )

  if (!enableAppTheming) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }

  return (
    <UserThemeContext.Provider value={contextValue}>
      <DMThemeGeneratedProvider theme={theme}>
        {children}
      </DMThemeGeneratedProvider>
    </UserThemeContext.Provider>
  )
}
