export type DMThemePresetKey =
  | "SystemPreference"
  | "Default"
  | "Sepia"
  | "HighContrast"
  | "DefaultDark"
  | "HighContrastDark"
  | "SpaceBlue"
  | "DarkGrey"
  | "Custom"

export type SelectedTheme = {
  preset: DMThemePresetKey
  displayName: string
  backgroundColor: string
  accentColor: string
  contrast: number
  isSecondaryEnabled: boolean
  secondaryBackgroundColor: string
  secondaryContrast: number
  mode?: "light" | "dark" | "custom" | "system"
  isEditorWhite?: boolean
}

export const themePresetsDefinition = {
  SystemPreference: {
    displayName: "System Preference",
    preset: "SystemPreference",
    mode: "system",
  },
  Default: {
    displayName: "Default",
    preset: "Default",
    mode: "light",
    backgroundColor: "#FBFCFE",
    contrast: 20,
    accentColor: "#104FC6",
    isSecondaryEnabled: true,
    secondaryBackgroundColor: "#0C162E",
    secondaryContrast: 24,
  },
  HighContrast: {
    displayName: "High Contrast",
    preset: "HighContrast",
    mode: "light",
    backgroundColor: "#FCFBFE",
    contrast: 64,
    accentColor: "#104FC6",
    isSecondaryEnabled: true,
    secondaryBackgroundColor: "#0C162E",
    secondaryContrast: 64,
  },
  Sepia: {
    displayName: "Sepia",
    preset: "Sepia",
    mode: "light",
    backgroundColor: "#F9F5F0",
    contrast: 20,
    accentColor: "#104FC6",
    isSecondaryEnabled: true,
    secondaryBackgroundColor: "#0C162E",
    secondaryContrast: 24,
  },
  DefaultDark: {
    displayName: "Default Dark",
    preset: "DefaultDark",
    mode: "dark",
    backgroundColor: "#101010",
    contrast: 20,
    accentColor: "#104FC6",
    isSecondaryEnabled: false,
    secondaryBackgroundColor: "#101010",
    secondaryContrast: 20,
  },
  HighContrastDark: {
    displayName: "High Contrast",
    preset: "HighContrastDark",
    mode: "dark",
    backgroundColor: "#101010",
    contrast: 64,
    accentColor: "#104FC6",
    isSecondaryEnabled: false,
    secondaryBackgroundColor: "#101010",
    secondaryContrast: 64,
  },
  SpaceBlue: {
    displayName: "Space Blue",
    preset: "SpaceBlue",
    mode: "dark",
    backgroundColor: "#040A1E",
    contrast: 24,
    accentColor: "#104FC6",
    isSecondaryEnabled: false,
    secondaryBackgroundColor: "#040A1E",
    secondaryContrast: 24,
  },
  DarkGrey: {
    displayName: "Dark Grey",
    preset: "DarkGrey",
    mode: "dark",
    backgroundColor: "#191A23",
    contrast: 24,
    accentColor: "#FFFFFF",
    isSecondaryEnabled: false,
    secondaryBackgroundColor: "#0C162E",
    secondaryContrast: 24,
  },
  Custom: {
    displayName: "Custom",
    preset: "Custom",
    mode: "custom",
  },
} as const
