import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/cloud/app/[locale]/_components/CommandSPreventer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SupernovaLiveblocksProvider"] */ "/vercel/path0/apps/cloud/app/[locale]/_components/SupernovaLiveblocksProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthDebugOverlay"] */ "/vercel/path0/apps/cloud/features/auth/AuthDebugOverlay.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/vercel/path0/apps/cloud/features/auth/SessionProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SessionUpdater"] */ "/vercel/path0/apps/cloud/features/auth/SessionUpdater.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ImpersonationBanner"] */ "/vercel/path0/apps/cloud/features/impersonation/ImpersonationBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useImpersonation"] */ "/vercel/path0/apps/cloud/features/impersonation/useImpersonation.ts");
;
import(/* webpackMode: "eager", webpackExports: ["SegmentGroupManager"] */ "/vercel/path0/apps/cloud/features/segment/SegmentGroupManager.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SegmentIdentifier"] */ "/vercel/path0/apps/cloud/features/segment/SegmentIdentifier.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SegmentOnboardingGroupManager"] */ "/vercel/path0/apps/cloud/features/segment/SegmentOnboardingGroupManager.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SegmentPageScope"] */ "/vercel/path0/apps/cloud/features/segment/SegmentPageScope.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SegmentPageTracker"] */ "/vercel/path0/apps/cloud/features/segment/SegmentPageTracker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SegmentProvider","useSegment"] */ "/vercel/path0/apps/cloud/features/segment/SegmentProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/cloud/features/support/SupportInit.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserThemeProvider"] */ "/vercel/path0/apps/cloud/features/theme/UserThemeProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/cloud/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/cloud/providers/LegacyPlanDialogProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/cloud/providers/PromoteUpgradeDialogProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/cloud/ui/styles/comments.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/cloud/ui/table/DndProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FeatureFlagsProvider"] */ "/vercel/path0/apps/cloud/utils/featureFlags/FeatureFlagsProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LocalStorageHandler"] */ "/vercel/path0/apps/cloud/utils/LocalStorageHandler.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/cloud/utils/QueryProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SelectionStateProvider"] */ "/vercel/path0/apps/cloud/utils/SelectionProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UIContextProvider"] */ "/vercel/path0/apps/cloud/utils/UIContextProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--dm-font-family\"}],\"variableName\":\"InterFont\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"Fira_Code\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--dm-font-family-mono\"}],\"variableName\":\"FiraCodeFont\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/darkmatter/src/index.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/darkmatter/styles.css");
