import { DMIcon } from "@supernovaio/dm"

type Props = {
  description: string
  icon: JSX.Element
}

export function ProductFeatureLine({ description, icon }: Props) {
  return (
    <div className="flex flex-row items-center gap-8">
      <DMIcon size="small" svg={icon} />
      <p className="text-body text-neutral">{description}</p>
    </div>
  )
}
