"use client"

import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"

import { UserTheme } from "@supernovaio/sdk"

import {
  usePersistentStorage,
  useStorageValueMap,
} from "../hooks/usePersistentStorage"

import { z } from "zod"

type Context = {
  isSidebarForceCollapsedOnCurrentRoute: boolean
  sidebarCollapsed: boolean
  toggleSidebarCollapsed: () => void
  setSidebarCollapsed: (isCollapsed: boolean) => void
  blockTokenSelectionMoreTypesExpanded: boolean
  toggleBlockTokenSelectionMoreTypesExpanded: () => void
  setBlockTokenSelectionMoreTypesExpanded: (isExpanded: boolean) => void
  cmdkShown: boolean
  toggleCmdk: () => void
  setCmdkShown: React.Dispatch<React.SetStateAction<boolean>>
  getAssetsNavigationSourceExpandedState: (sourceId: string) => boolean
  setAssetsNavigationSourceExpandedState: (
    sourceId: string,
    isExpanded: boolean
  ) => void
  getComponentsNavigationSourceExpandedState: (sourceId: string) => boolean
  setComponentsNavigationSourceExpandedState: (
    sourceId: string,
    isExpanded: boolean
  ) => void
  getDocsNavigationGroupExpandedStates: () =>
    | Record<string, boolean>
    | undefined
  getDocsNavigationGroupExpandedState: (groupId: string) => boolean | undefined
  setDocsNavigationGroupExpandedState: (
    groupId: string,
    isExpanded: boolean
  ) => void
  getDocsPropertyPanelSectionState: (sectionId: string) => boolean | undefined
  setDocsPropertyPanelSectionState: (
    sectionId: string,
    isExpanded: boolean
  ) => void
  getApprovalsSuggestionDismissed: (wsId: string, dsId: string) => boolean
  setApprovalsSuggestionDismissed: (
    wsId: string,
    dsId: string,
    approvalsSuggestionDismissed: boolean
  ) => void
  selectedTheme: UserTheme | null
  setSelectedTheme: (theme: UserTheme | null) => void
}

const UIContext = createContext<Context>({
  isSidebarForceCollapsedOnCurrentRoute: false,
  sidebarCollapsed: false,
  toggleSidebarCollapsed: () => null,
  setSidebarCollapsed: () => null,
  blockTokenSelectionMoreTypesExpanded: false,
  toggleBlockTokenSelectionMoreTypesExpanded: () => null,
  setBlockTokenSelectionMoreTypesExpanded: () => null,
  cmdkShown: false,
  toggleCmdk: () => null,
  setCmdkShown: () => null,
  getAssetsNavigationSourceExpandedState: () => true,
  setAssetsNavigationSourceExpandedState: () => {},
  getComponentsNavigationSourceExpandedState: () => true,
  setComponentsNavigationSourceExpandedState: () => {},
  getDocsNavigationGroupExpandedState: () => undefined,
  setDocsNavigationGroupExpandedState: () => {},
  getDocsNavigationGroupExpandedStates: () => ({}),
  getDocsPropertyPanelSectionState: () => undefined,
  setDocsPropertyPanelSectionState: () => {},
  getApprovalsSuggestionDismissed: () => false,
  setApprovalsSuggestionDismissed: () => {},
  selectedTheme: null,
  setSelectedTheme: () => null,
})

const uiContextDataSchema = z.object({
  sidebarCollapsed: z.boolean(),
  blockTokenSelectionMoreTypesExpanded: z.boolean(),
  assetsNavigationSourceExpandedStates: z.record(z.boolean()),
  componentsNavigationSourceExpandedStates: z.record(z.boolean()),
  documentationGroupExpandedStates: z.record(z.boolean()),
  documentationPropertyPanelSectionStates: z.record(z.boolean()),
  approvalsSuggestionDismissed: z.record(z.record(z.boolean())),
  selectedTheme: z
    .object({
      preset: z.enum([
        "SystemPreference",
        "Default",
        "Sepia",
        "HighContrast",
        "DefaultDark",
        "HighContrastDark",
        "SpaceBlue",
        "DarkGrey",
        "Custom",
      ]),
      displayName: z.string().optional(),
      backgroundColor: z.string().optional(),
      accentColor: z.string().optional(),
      contrast: z.number().optional(),
      isSecondaryEnabled: z.boolean().optional(),
      secondaryBackgroundColor: z.string().optional(),
      secondaryContrast: z.number().optional(),
      isEditorWhite: z.boolean().optional(),
    })
    .nullable()
    .default(null),
})

type UIContextState = z.infer<typeof uiContextDataSchema>

const UI_STATE = "uiState" as const

export function UIContextProvider({ children }: React.PropsWithChildren) {
  const localStorage = usePersistentStorage()

  const map = useStorageValueMap(uiContextDataSchema, (storage) =>
    storage.getItem(UI_STATE)
  )

  const [uiState, setUIState] = useState<UIContextState>(
    map((state) => state) || {
      sidebarCollapsed: false,
      blockTokenSelectionMoreTypesExpanded: false,
      assetsNavigationSourceExpandedStates: {},
      componentsNavigationSourceExpandedStates: {},
      documentationGroupExpandedStates: {},
      documentationPropertyPanelSectionStates: {},
      approvalsSuggestionDismissed: {},
      selectedTheme: null,
    }
  )

  // Sidebar collapsed
  // --- --- --- --- --- --- --- --- --- ---

  // Some pages used to require the sidebar to be always collapsed, which means disabling the toggle.
  // This feature got removed for that feature, but we can keep this system if we need it in the future
  const isSidebarForceCollapsedOnCurrentRoute = useMemo(() => {
    return false
  }, [])

  // Some pages require the sidebar to be always collapsed, but without changing the saved state,
  // so it can be restored after navigating away from those pages
  const isEffectiveSidebarCollapsed = useMemo(() => {
    if (isSidebarForceCollapsedOnCurrentRoute) {
      return true
    }
    return uiState.sidebarCollapsed
  }, [isSidebarForceCollapsedOnCurrentRoute, uiState.sidebarCollapsed])

  const setSidebarCollapsed = useCallback(
    (isCollapsed: boolean) => {
      const newState = { ...uiState, sidebarCollapsed: isCollapsed }
      setUIState(newState)
      localStorage.setItem(UI_STATE, newState)
    },
    [localStorage, uiState]
  )

  const toggleSidebarCollapsed = useCallback(() => {
    setSidebarCollapsed(!uiState.sidebarCollapsed)
  }, [setSidebarCollapsed, uiState.sidebarCollapsed])

  // Block token selection dialog: more types expanded
  // --- --- --- --- --- --- --- --- --- ---

  const setBlockTokenSelectionMoreTypesExpanded = useCallback(
    (isExpanded: boolean) => {
      const newState = {
        ...uiState,
        blockTokenSelectionMoreTypesExpanded: isExpanded,
      }
      setUIState(newState)
      localStorage.setItem(UI_STATE, newState)
    },
    [localStorage, uiState]
  )

  const toggleBlockTokenSelectionMoreTypesExpanded = useCallback(() => {
    setBlockTokenSelectionMoreTypesExpanded(
      !uiState.blockTokenSelectionMoreTypesExpanded
    )
  }, [
    setBlockTokenSelectionMoreTypesExpanded,
    uiState.blockTokenSelectionMoreTypesExpanded,
  ])

  // CMD+k
  // --- --- --- --- --- --- --- --- --- ---

  const [cmdkShown, setCmdkShown] = useState<boolean>(false)

  const toggleCmdk = useCallback(() => {
    setCmdkShown((prev) => {
      return !prev
    })
  }, [])

  // Assets navigation
  // --- --- --- --- --- --- --- --- --- ---

  const getAssetsNavigationSourceExpandedState = useCallback(
    (sourceId: string) => {
      return uiState.assetsNavigationSourceExpandedStates[sourceId] ?? true
    },
    [uiState]
  )

  const setAssetsNavigationSourceExpandedState = useCallback(
    (sourceId: string, isExpanded: boolean) => {
      const newState = {
        ...uiState,
        assetsNavigationSourceExpandedStates: {
          ...uiState.assetsNavigationSourceExpandedStates,
          [sourceId]: isExpanded,
        },
      }
      setUIState(newState)
      localStorage.setItem(UI_STATE, newState)
    },
    [localStorage, uiState]
  )

  // Components navigation sources
  // --- --- --- --- --- --- --- --- --- ---

  const getComponentsNavigationSourceExpandedState = useCallback(
    (sourceId: string) => {
      return uiState.componentsNavigationSourceExpandedStates[sourceId] ?? true
    },
    [uiState]
  )

  const setComponentsNavigationSourceExpandedState = useCallback(
    (sourceId: string, isExpanded: boolean) => {
      const newState = {
        ...uiState,
        componentsNavigationSourceExpandedStates: {
          ...uiState.componentsNavigationSourceExpandedStates,
          [sourceId]: isExpanded,
        },
      }
      setUIState(newState)
      localStorage.setItem(UI_STATE, newState)
    },
    [localStorage, uiState]
  )

  // Docs navigations groups
  // --- --- --- --- --- --- --- --- --- ---

  const getDocsNavigationGroupExpandedStates = useCallback(() => {
    return map((state) => state)?.documentationGroupExpandedStates
  }, [map])

  const getDocsNavigationGroupExpandedState = useCallback(
    (groupId: string) => {
      return uiState.documentationGroupExpandedStates[groupId]
    },
    [uiState]
  )

  const setDocsNavigationGroupExpandedState = useCallback(
    (groupId: string, isExpanded: boolean) => {
      const newState = {
        ...uiState,
        documentationGroupExpandedStates: {
          ...uiState.documentationGroupExpandedStates,
          [groupId]: isExpanded,
        },
      }
      setUIState(newState)
      localStorage.setItem(UI_STATE, newState)
    },
    [localStorage, uiState]
  )

  // Docs property panel sections
  // --- --- --- --- --- --- --- --- --- ---

  const getDocsPropertyPanelSectionState = useCallback(
    (sectionId: string) => {
      return uiState.documentationPropertyPanelSectionStates[sectionId]
    },
    [uiState]
  )

  const setDocsPropertyPanelSectionState = useCallback(
    (sectionId: string, isExpanded: boolean) => {
      const newState = {
        ...uiState,
        documentationPropertyPanelSectionStates: {
          ...uiState.documentationPropertyPanelSectionStates,
          [sectionId]: isExpanded,
        },
      }
      setUIState(newState)
      localStorage.setItem(UI_STATE, newState)
    },
    [localStorage, uiState]
  )

  // Approval suggestions
  // --- --- --- --- --- --- --- --- --- ---

  const getApprovalsSuggestionDismissed = useCallback(
    (wsId: string, dsId: string) => {
      return uiState.approvalsSuggestionDismissed[wsId]?.[dsId] ?? false
    },
    [uiState]
  )

  const setApprovalsSuggestionDismissed = useCallback(
    (wsId: string, dsId: string, approvalsSuggestionDismissed: boolean) => {
      const newState = {
        ...uiState,
        approvalsSuggestionDismissed: {
          ...uiState.approvalsSuggestionDismissed,
          [wsId]: {
            ...uiState.approvalsSuggestionDismissed[wsId],
            [dsId]: approvalsSuggestionDismissed,
          },
        },
      }
      setUIState(newState)
      localStorage.setItem(UI_STATE, newState)
    },
    [localStorage, uiState]
  )

  // Theme
  // --- --- --- --- --- --- --- --- --- ---

  const setSelectedTheme = useCallback(
    (theme: UserTheme | null) => {
      const newState = { ...uiState, selectedTheme: theme }
      setUIState(newState)
      localStorage.setItem(UI_STATE, newState)
    },
    [localStorage, uiState]
  )

  // Context
  // --- --- --- --- --- --- --- --- --- ---

  const context = useMemo<Context>(
    () => ({
      isSidebarForceCollapsedOnCurrentRoute,
      sidebarCollapsed: isEffectiveSidebarCollapsed,
      toggleSidebarCollapsed,
      setSidebarCollapsed,
      cmdkShown,
      setCmdkShown,
      toggleCmdk,
      blockTokenSelectionMoreTypesExpanded:
        uiState.blockTokenSelectionMoreTypesExpanded,
      toggleBlockTokenSelectionMoreTypesExpanded,
      setBlockTokenSelectionMoreTypesExpanded,
      getAssetsNavigationSourceExpandedState,
      setAssetsNavigationSourceExpandedState,
      getComponentsNavigationSourceExpandedState,
      setComponentsNavigationSourceExpandedState,
      getDocsNavigationGroupExpandedState,
      setDocsNavigationGroupExpandedState,
      getDocsNavigationGroupExpandedStates,
      getDocsPropertyPanelSectionState,
      setDocsPropertyPanelSectionState,
      getApprovalsSuggestionDismissed,
      setApprovalsSuggestionDismissed,
      selectedTheme: uiState.selectedTheme,
      setSelectedTheme,
    }),
    [
      isSidebarForceCollapsedOnCurrentRoute,
      isEffectiveSidebarCollapsed,
      cmdkShown,
      toggleSidebarCollapsed,
      setSidebarCollapsed,
      toggleCmdk,
      uiState.blockTokenSelectionMoreTypesExpanded,
      toggleBlockTokenSelectionMoreTypesExpanded,
      setBlockTokenSelectionMoreTypesExpanded,
      getAssetsNavigationSourceExpandedState,
      setAssetsNavigationSourceExpandedState,
      getComponentsNavigationSourceExpandedState,
      setComponentsNavigationSourceExpandedState,
      getDocsNavigationGroupExpandedState,
      setDocsNavigationGroupExpandedState,
      getDocsNavigationGroupExpandedStates,
      getDocsPropertyPanelSectionState,
      setDocsPropertyPanelSectionState,
      getApprovalsSuggestionDismissed,
      setApprovalsSuggestionDismissed,
      uiState.selectedTheme,
      setSelectedTheme,
    ]
  )

  return <UIContext.Provider value={context}>{children}</UIContext.Provider>
}

export const useUIContext = () => {
  return useContext(UIContext)
}
