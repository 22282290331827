import React from "react"

import { useTranslations } from "next-intl"

import { DMButtonUpgrade, DMConfirmationDialog } from "@supernovaio/dm"

import { useLegacyPlanDialog } from "@supernovaio/cloud/providers/LegacyPlanDialogProvider"
import { IconCheck } from "@supernovaio/icons"
import {
  WorkspaceSubscription,
  WorkspaceSubscriptionProductCode,
} from "@supernovaio/sdk"
import { WorkspaceProduct } from "@supernovaio/sdk/src/model/workspaces/SDKWorkspaceProduct"

import { PREVIOUS_LEVEL_PRODUCT, findPrice } from "../../utils/productUtils"
import { ProductFeatureLine } from "../components/ProductFeatures/ProductFeatureLine"
import { BoldText } from "../components/i18n"

export type PromoteUpgradeDialogProps = {
  title?: string
  description?: React.ReactNode
  onCancel: () => void
  onConfirm: (stripePriceId: string) => void
  isOpen: boolean
  targetProduct: WorkspaceProduct
  subscription: WorkspaceSubscription
  selectPlanRoute: string
  hasUpgradePermission: boolean
  isLoading: boolean
  shouldDisplayFeatures?: boolean
}

export function PromoteUpgradeDialog({
  title,
  description,
  isOpen,
  onCancel,
  onConfirm,
  targetProduct,
  subscription,
  selectPlanRoute,
  hasUpgradePermission,
  isLoading,
  shouldDisplayFeatures,
}: PromoteUpgradeDialogProps) {
  const t = useTranslations()
  const {
    closeLegacyDialog,
    openLegacyDialog,
    isOpen: isLegacyPlanDialogOpen,
  } = useLegacyPlanDialog()

  const targetProductPrice = findPrice({
    interval: subscription.planInterval,
    prices: targetProduct.prices,
  })
  const isLegacy = Boolean(subscription.legacyVersion)

  const handleConfirmationButton = async () => {
    if (!targetProductPrice) {
      return
    }

    if (isLegacy) {
      if (!(await openLegacyDialog())) {
        return
      }
    }

    onConfirm(targetProductPrice.stripePriceId)
    closeLegacyDialog()
  }

  const getButtonConfirmLabel = () => {
    if (!hasUpgradePermission) {
      return t("UpgradePromotion.Buttons.contactAdmin")
    }

    if (
      !subscription.isPricePerCreator ||
      targetProduct.code === WorkspaceSubscriptionProductCode.enterprise
    ) {
      return t("UpgradePromotion.Buttons.contactUs")
    }

    return t("UpgradePromotion.Buttons.upgrade")
  }

  return (
    <DMConfirmationDialog
      buttonLabelCancel={t("UpgradePromotion.Buttons.cancel")}
      buttonLabelConfirm={getButtonConfirmLabel()}
      description={
        description ?? (
          <DialogContent
            subscription={subscription}
            targetPlan={targetProduct.code}
            targetProductPrice={targetProductPrice}
            shouldDisplayFeatures={shouldDisplayFeatures}
          />
        )
      }
      isLoading={isLoading}
      moreActionsSlot={
        hasUpgradePermission && (
          <DMButtonUpgrade href={selectPlanRoute} variant="ghost">
            {t("UpgradePromotion.Buttons.viewPlans")}
          </DMButtonUpgrade>
        )
      }
      open={isOpen && !isLegacyPlanDialogOpen}
      title={
        title ??
        `${t("UpgradePromotion.upgradeTo")} ${t(
          `Subscription.product.${targetProduct.code}`
        )}`
      }
      variant="upgrade"
      onCancel={onCancel}
      onConfirm={handleConfirmationButton}
      onOpenChange={onCancel}
    />
  )
}

type DialogContentProps = Pick<
  PromoteUpgradeDialogProps,
  "subscription" | "shouldDisplayFeatures"
> & {
  targetProductPrice?: WorkspaceProduct["prices"][0]
  targetPlan: WorkspaceSubscriptionProductCode
}

function DialogContent({
  subscription,
  targetProductPrice,
  targetPlan,
  shouldDisplayFeatures,
}: DialogContentProps) {
  const t = useTranslations()

  const features = [
    ...(targetProductPrice?.stripeProductFeatures ?? []),
    ...(targetProductPrice?.stripeProductAdditionalFeatures ?? []),
  ]

  return (
    <div className="flex flex-col gap-16">
      <p className="text-body whitespace-pre-line leading-[20px] text-neutral">
        {shouldDisplayFeatures
          ? t.rich("UpgradePromotion.dialogDescription", {
              currentPlan: t(`Subscription.product.${subscription.product}`),
              targetPlan: t(`Subscription.product.${targetPlan}`),
            })
          : t.rich("UpgradePromotion.dialogDescriptionWithoutFeatures", {
              currentPlan: t(`Subscription.product.${subscription.product}`),
              targetPlan: t(`Subscription.product.${targetPlan}`),
              bold: BoldText,
            })}
      </p>
      {shouldDisplayFeatures && (
        <div className="border-neutral-faded flex flex-col gap-8 rounded border p-8">
          <p className="text-body text-neutral-faded font-bold leading-[20px]">
            {t("UpgradePromotion.featuresTitle", {
              plan: t(
                `Subscription.product.${PREVIOUS_LEVEL_PRODUCT[targetPlan]}`
              ),
            })}
          </p>
          {features.map((feature: string, index: number) => (
            <ProductFeatureLine
              // eslint-disable-next-line react/no-array-index-key
              key={`${index}_${feature}`}
              description={feature}
              icon={<IconCheck />}
            />
          ))}
        </div>
      )}
    </div>
  )
}
